
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/7de8fbf09bf2a439/packages/bookings-form-widget/src/components/BookingsForm/Widget/index.tsx';
  import {
    HttpProvider,
  } from '@wix/yoshi-flow-editor';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor-runtime/build/esm/i18next/init';



  import stylesParams from '/home/builduser/work/7de8fbf09bf2a439/packages/bookings-form-widget/src/components/BookingsForm/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://8a26eadef7db4e1f8c80ebbe1517ed8e@sentry.wixpress.com/1627',
      id: '8a26eadef7db4e1f8c80ebbe1517ed8e',
      projectName: 'bookings-form-widget',
      teamName: 'bookings',
    };

  var UserComponent = getWidgetWrapper({
      initI18n,
      HttpProvider,
      withStylesHoc: withStyles,
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'BookingsForm',
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
